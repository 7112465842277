@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

body {
  font-family: "Lato", sans-serif !important;
}

* {
  font-family: "Lato", sans-serif !important;
  box-sizing: content-box;
  font-size: 1rem;
  text-align: center;
}

h1 {
  font-weight: 900;
}

input,
select {
  text-align: left;
  padding: 0.3rem 0.4rem;
  margin: 0.5rem 0 0.5rem 0;
  border: 1px solid #a4a4a4;
  color: #a2a2a2;
  width: 50%;
}

select {
  background-color: transparent;
  padding: 0.3rem 0.8rem;
  margin: 0.5rem 0 0.5rem 0.8rem;
  border: 1px solid #a4a4a4;
  color: #a2a2a2;
  height: 2rem;
}

button {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  background-color: transparent;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: #eeeff2;
}
.imageCover {
  height: auto;
  max-width: 400px;
  margin-bottom: -40px;
}

.RadioButton .input-hidden {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  opacity: 0;
}

.form.d-flex {
  display: flex;
  justify-content: space-between;
}

.form.d-button {
  width: 250px;
}
.reason {
  flex-wrap: wrap;
}

.reason .Checkbox label {
  height: 100%;
}

.app {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  padding: 0px;
  margin-top: 30px;
}

.app .wrapper {
  display: flex;
  background: #fff;
  max-width: 500px;
  justify-content: center;
  width: 100%;
  min-height: 150px;
  align-items: start;
  border-radius: 10px;
  padding: 50px 100px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  position: relative;
}

.Recommend {
  width: 100%;
}

.back-btn {
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #434343;
  font-size: 15px;
}

.footer-logo img {
  /*filter: invert(1);
  opacity: 0.1;*/
  width: 100%;
  max-width: 150px;
}
.footer-logo {
  margin-top: 40px;
  padding: 10px;
}

.app .wrapper h1 {
  color: #404040;
  font-size: 30px;
  margin: 0 0 50px;
}

.form .RadioButton {
  width: 20%;
  padding: 0 10px;
}

.RadioButton input[type="radio"]:checked + label > img {
  transform: scale(1);
}
.RadioButton input[type="radio"] + label > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 500ms all;
}
.Checkbox input {
  opacity: 0;
  z-index: 99;
  width: 100%;
  margin: 0;
  cursor: pointer;
  position: absolute;
  padding: 0 !important;
  left: 0;
  right: 0;

  top: 0;
  bottom: 0;
  height: 100%;
}

.Checkbox {
  margin: 10px 0px;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  border: solid 1px #aaa;
  /* background: #666981; */
  display: block;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 48%;
}

.Checkbox label {
  width: 100%;
  padding: 25px 5px;
  box-sizing: border-box;
  word-break: break-word;
}

.inner-wrapper,
.Negative {
  width: 100%;
}

.inner-wrapper-rating {
  width: 100%;
  /* padding-top: 40px; */
  padding-bottom: 40px;
}

.division-wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputtext {
  border: none;
  width: 100%;
  background: #f2f2f2;
  padding: 13px 20px;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
}

.Checkbox :checked + label {
  color: #fff;
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: #666981;
  border-radius: 2px;
}

.nav-button {
  display: block;
  width: 100%;
  margin-top: 30px;
}
.nav-button button {
  color: #fff;
  min-width: 80px;
  padding: 10px 35px;
  border-radius: 3px;
  background: #32b1ae;
  margin: 0 auto;
  border: solid 1px #32b1ae;
  cursor: pointer;
}

.nav-button-skip {
  display: block;
  width: 100%;
  margin-top: 30px;
}

.nav-button-skip button {
  color: #32b1ae;
  min-width: 80px;
  padding: 10px 35px;
  border-radius: 3px;
  background: #ffffff;
  margin: 0 auto;
  border: solid 1px #32b1ae;
  cursor: pointer;
}

.RadioButton {
  position: relative;
}
.form.d-flex.Recommend-rating {
  margin: 85px 0 80px;
}
.Recommend .RadioButton {
  width: auto;
  padding: 8px 14px;
  border: solid 1px #353535;
  border-radius: 5px;
  font-weight: bold;
  position: relative;
}
.Recommend .RadioButton:hover {
  background: #666981;
  color: #fff;
}

.Recommend-rating .very-like {
  position: absolute;
  right: 0;
  bottom: 0;
}
.Recommend-rating .like {
  position: absolute;
  left: 0;
  bottom: 0;
}
.Recommend-rating {
  padding-bottom: 35px;
  position: relative;
}
.Recommend-rating .likely_L {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Thankyou {
  width: 100%;
  align-self: center;
  margin-bottom: 70px;
}
.Thankyou h1 {
  margin: 0 !important;
}

.Department-screen {
  flex-wrap: wrap;
}

.Department-screen .RadioButton {
  width: 48%;
  box-sizing: border-box;
  border: 1px solid #6a6c6f;
  border-radius: 4px;
  min-height: 70px;
  padding: 0;
  margin-bottom: 20px;
}
.Department-screen .RadioButton label {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 30%;
  height: 100%;
  box-sizing: border-box;
  color: #414141;
  text-transform: capitalize;
}
.Department-screen .RadioButton .input-hidden {
  margin: 0;
}
.Department-screen .RadioButton label img {
  width: auto !important;
  height: calc(100% - 20px) !important;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
}

.Helped-service {
  flex-wrap: wrap;
  justify-content: flex-start !important;
}

.Helped-service .RadioButton {
  box-sizing: border-box;
  width: 30%;
  margin: 0 1.5% 20px;
  background-color: #eeeff2;
  padding: 20px !important;
  border-radius: 4px;
}

.Helped-service .RadioButton label {
  display: inline-block;
  color: #454545;
  /* text-transform: capitalize; */
}

.Helped-service .RadioButton label img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 100%;
  margin-bottom: 10px;
  object-fit: cover;
  overflow: hidden;
  clear: both;
  display: table;
  margin: 0 auto 20px;
}

.Helped-service .RadioButton .input-hidden {
  margin: 0;
}

.attendee-wrapper {
  margin-bottom: 30px;
}

.attendee-wrapper img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

.attendee-wrapper label {
  margin: 10px auto 0;
  display: table;
  color: #404040;
}

.Service-rating {
  flex-wrap: wrap;
  justify-content: center !important;
}

.Service-rating .Checkbox {
  width: auto;
  margin: 10px;
}

.Service-rating .Checkbox label {
  padding: 15px 40px;
}
.Service-rating textarea {
  margin-top: 50px;
}
.back-btn:before {
  /*content: "\f060";*/
  /*font-family: FontAwesome;*/
  margin-right: 12px;
}
.react-confirm-alert-button-group {
  display: flow-root !important;
}

/*.Thankyou img {*/
/*  width: 100px;*/
/*}*/
.Helped {
  width: 100%;
}

.divisionSelect {
  width: 100%;
}

.divisionSelect .css-26l3qy-menu {
  margin-top: 0px;
  border-radius: 6px;
  padding-top: 0px;
  margin-top: 1px;
  box-shadow: 0px 1px 5px 1px rgba(199,199,199,1);
}

.css-26l3qy-menu div {
  margin-left: 3px;
  width: calc(100% - 6px);
  border-bottom: 1px solid #e6e6e6;
}

.css-26l3qy-menu div:last-child {
  border-bottom: unset;
}

/**-Responsive--**/

@media only screen and (max-width: 1024px) {
  .app {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .app .wrapper {
    width: 100%;
    box-sizing: border-box;
  }
  .form .RadioButton {
    padding: 0 3px;
  }
  .form .RadioButton {
    padding: 0 20px;
  }
  .Recommend .RadioButton {
    width: 100%;
    padding: 8px !important;
    margin: 5px 0;
    box-sizing: border-box;
  }

  .Recommend-rating .likely_L {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
  }
  .Recommend-rating .very-like,
  .Recommend-rating .like {
    position: static;
  }
  .Recommend-rating {
    flex-direction: column;
    padding: 0;
  }
  .app .wrapper {
    padding: 30px 10px;
  }
  .Department-screen .RadioButton {
    width: 100%;
  }

  .Helped-service {
    justify-content: space-between !important;
  }

  .Helped-service .RadioButton label {
    word-break: break-word;
    font-size: 14px;
  }

  .Helped-service .RadioButton label img {
    width: 75px !important;
    height: 75px !important;
  }

  .Helped-service .RadioButton {
    width: 47%;
    margin: 0 0 20px;
    background-color: #eeeff2;
    padding: 15px 10px !important;
  }

  .Service-rating {
    justify-content: space-between !important;
  }

  .Service-rating .Checkbox {
    width: 48%;
    margin: 0 0 10px;
  }

  .Service-rating .Checkbox label {
    padding: 10px;
  }
  .thankyouImage {
    height: 3rem;
    width: 3rem;
  }
}

.change-lang {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 30px;
}

.change-lang-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 15px;
}

.drop-down-content {
  position: absolute;
  background-color: #f9f9f9;
  width: 62px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media only screen and (max-width: 575px) {
  .form.d-flex {
    width: 100%;
  }

  .form .RadioButton {
    padding: 0 3px;
  }
  .nav-button {
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .nav-button button {
    width: 100%;
    padding: 12px 35px;
    box-sizing: border-box;
    font-size: 20px;
  }
  .nav-button-skip {
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .nav-button-skip button {
    width: 100%;
    padding: 12px 35px;
    box-sizing: border-box;
    font-size: 20px;
  }
  .Checkbox {
    width: 100%;
  }
  .Thankyou {
    margin: 25px 0 90px;
  }

  .thankyouImage {
    height: 4rem;
    width: 4rem;
  }
}